<template>
	<div class="wrapper-modal">
		<b-modal
			:id="idModal"
			:hide-header="true"
			:hide-footer="true"
			:size="size"
			v-model="openModal"
			:no-close-on-backdrop="true"
			:close-on-esc="false"
			centered
			>
			<div :class="classCustom">
				<slot></slot>
			</div>
		</b-modal>

		<!--Modal de Load (VUEX)-->
		<div
		:class="'modal-overlay'"
		:hide-header="true"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:close-on-esc="false"
		centered
		>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Modal',

		props: {
			name: {
				type: String,
				required: true
			},
			idModal: {
				type: String,
				default: 'modal-primary'
			},
			openModal: {
				type: Boolean,
				default: false
			},
			classCustom: {
				type: String,
				default: ''
			},
			size: {
				type: String,
				default: ''
			},
		},
	}
</script>


<style lang="scss">

	// .wrapper-icon-modal {

	// }

	// .modal-content{

	// }

	//DESIGN MODAL DE LOAD (VUEX)
	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: opacity 0.3s ease-in-out;
		width: 100%;
	}
	.modal-overlay--active {
		z-index: 100000;
		background-color: rgba(black, .98);
		width: 100vw;
		height: 100%;
		min-height: calc(100% - 3.5rem);
	}
	.modal-wrapper {

		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
		padding: 15px;
		transition: all 0.3s ease;
		max-height: 80%;
		max-width: 500px;
		width: 85%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 3px solid black;
        border-radius: 0px !important;
        background-color: black !important;
	}
</style>
